/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tabs, Table, Button, Modal, Form, Input, Popconfirm } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import http from "../../services/http";
import { showSuccess, showError } from "../../services/notification";
import Header from "../../components/Header";
import { FiMessageSquare } from "react-icons/fi";

const { TabPane } = Tabs;

const Preguntas = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [preguntasRespondidas, setPreguntasRespondidas] = useState([]);
  const [preguntasPorResponder, setPreguntasPorResponder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [currentPregunta, setCurrentPregunta] = useState(null);
  const [currentRespuesta, setCurrentRespuesta] = useState(null);

  useEffect(() => {
    fetchPreguntas();
  }, []);

  const fetchPreguntas = async () => {
    setLoading(true);
    const responseRespondidas = await http.get("message/allVisible");
    const responsePorResponder = await http.get("message/allMessages");

    if (responseRespondidas && responseRespondidas.data) {
      setPreguntasRespondidas(responseRespondidas.data);
    }

    if (responsePorResponder && responsePorResponder.data) {
      const porResponder = responsePorResponder.data.filter(
        (msg) => !msg.visible
      );
      setPreguntasPorResponder(porResponder);
    }

    setLoading(false);
  };

  const columnsRespondidas = [
    {
      title: "Número Licitación",
      dataIndex: "licitacionNumero",
      key: "licitacionNumero",
    },
    {
      title: "Nombre Licitación",
      dataIndex: "licitacionNombre",
      key: "licitacionNombre",
    },

    {
      title: "Pregunta",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Respuesta",
      dataIndex: ["respuesta", "content"],
      key: "respuesta",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, record) => (
        <>
          <Button onClick={() => showEditModal(record)}>
            Editar Respuesta
          </Button>
        </>
      ),
    },
  ];

  const columnsPorResponder = [
    {
      title: "Número Licitación",
      dataIndex: "licitacionNumero",
      key: "licitacionNumero",
    },
    {
      title: "Nombre Licitación",
      dataIndex: "licitacionNombre",
      key: "licitacionNombre",
    },
    {
      title: "Pregunta",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, record) => (
        <>
          <Button onClick={() => showModal(record)}>Responder</Button>
          <Popconfirm
            title="¿Estás seguro de eliminar esta pregunta?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sí"
            cancelText="No"
            okButtonProps={{
              style: {
                minWidth: "60px",
                textAlign: "center",
                minHeight: "40px",
              },
            }}
            cancelButtonProps={{
              style: {
                minWidth: "60px",
                textAlign: "center",
                minHeight: "40px",
              },
            }}
          >
            <Button type="danger" style={{ marginLeft: 8 }}>
              Eliminar
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const showModal = (record) => {
    setCurrentPregunta(record);
    form.resetFields();
    setVisible(true);
  };

  const showEditModal = (record) => {
    setCurrentRespuesta(record.respuesta);
    editForm.setFieldsValue({ newContent: record.respuesta.content });
    setEditVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrentPregunta(null);
  };

  const handleEditCancel = () => {
    setEditVisible(false);
    setCurrentRespuesta(null);
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    const response = await http.post(`message/respuesta`, {
      MessageId: currentPregunta.id,
      Content: values.respuesta,
    });
    if (response && response.data) {
      showSuccess("La pregunta fue respondida exitosamente.");
      fetchPreguntas();
      form.resetFields();
      setVisible(false);
      setCurrentPregunta(null);
    } else {
      showError("Error al responder la pregunta.");
    }
  };

  const handleEditOk = async () => {
    const values = await editForm.validateFields();
    const response = await http.put(
      `message/editRespuesta/${currentRespuesta.id}`,
      {
        respuesta: values.newContent,
      }
    );
    if (response && response.status === 204) {
      showSuccess("La respuesta fue editada exitosamente.");
      fetchPreguntas();
      setEditVisible(false);
      setCurrentRespuesta(null);
    } else {
      showError("Error al editar la respuesta.");
    }
  };

  const handleDelete = async (id) => {
    const response = await http.delete(`/message/${id}`);
    if (response && response.status === 204) {
      showSuccess("La pregunta fue eliminada exitosamente.");
      fetchPreguntas();
    } else {
      showError("Error al eliminar la pregunta.");
    }
  };

  const handleTabChange = (key) => {
    fetchPreguntas();
  };

  return (
    <div className="preguntas card card-page">
      <Header title="Preguntas" icon={<FiMessageSquare />} />{" "}
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="Preguntas Respondidas" key="1">
          <Table
            columns={columnsRespondidas}
            dataSource={preguntasRespondidas}
            loading={loading}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="Preguntas por Responder" key="2">
          <Table
            columns={columnsPorResponder}
            dataSource={preguntasPorResponder}
            loading={loading}
            rowKey="id"
          />
        </TabPane>
      </Tabs>
      <Modal
        title="Responder Pregunta"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{ style: { minWidth: "100px", minHeight: "40px" } }}
        cancelButtonProps={{ style: { minWidth: "100px", minHeight: "40px" } }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Respuesta"
            name="respuesta"
            rules={[
              { required: true, message: "Por favor ingrese una respuesta" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Editar Respuesta"
        visible={editVisible}
        onCancel={handleEditCancel}
        onOk={handleEditOk}
        okButtonProps={{ style: { minWidth: "100px", minHeight: "40px" } }}
        cancelButtonProps={{ style: { minWidth: "100px", minHeight: "40px" } }}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            label="Nuevo Contenido"
            name="newContent"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el nuevo contenido",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Preguntas;
