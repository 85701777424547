/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FiDownload, FiEye, FiFolderPlus } from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";

import http from "../../../services/http";

import "./SolicitudesDocumentosAdicionales.scss";
import { Modal } from "antd";

const SolicitudesDocumentosAdicionales = (props) => {
  const { licitacion, solicitudId } = props;

  const [documentosAdicionales, setDocumentosAdicionales] = useState([]);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");


  useEffect(() => {
    async function getList() {
      const url = `licitaciones/${licitacion}/solicitudes/${solicitudId}/documentosadicionales`;
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDocumentosAdicionales(data.documentosAdicionales);
      }
    }
    getList();
  }, []);


  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    setPdfVisible(true)

  };

  const onDownload = (item) => {
    if (!item.id) {
      return;
    }

    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.id}`,
      "_blank"
    );
    win.focus();
  };

  const onDownloadAll = () => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/solicitud/${solicitudId}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <div className="solicitudes-documentos-adicionales">
      <div className="header">
        <div className="icon">
          <FiFolderPlus />
        </div>
        <div className="data">
          <div className="title">
            Documentación dentro de las 48 hs. y/u Observaciones
          </div>
          <div className="description">
            Lista de documentos cargados una vez cerrada la carpeta inicial.
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="icon"></th>
            <th>Nombre</th>
            {/* <th className="size">Tamaño</th>
            <th className="date">Fecha</th> */}
            <th className="download">
              {documentosAdicionales.length > 0 && (
                <div title="Descargar Todo" onClick={onDownloadAll}>
                  <FiDownload />
                </div>
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documentosAdicionales.map((documento, index) => (
            <tr key={index}>
              <td className="icon">
                <FileIcon
                  extension={documento.extension}
                  {...defaultStyles.pdf}
                />
              </td>
              <td>{documento.nombre}</td>
              {/* <td className="size">{documento.tamanioTexto}</td>
              <td className="date">{documento.fecha}</td> */}
              <td className="download">
                {documento.id && (
                  <div title="Descargar" onClick={() => onDownload(documento)}>
                    <FiDownload />
                  </div>
                )}
                {documento.extension === ".pdf" && (
                  <div title="Ver" onClick={() => onPreview(documento)}>
                    <FiEye />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default SolicitudesDocumentosAdicionales;
