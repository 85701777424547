/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import { getUser } from "../services/security";

import Bar from "./../components/Bar";
import SideBar from "./../components/SideBar";
import Inicio from "./Inicio/Inicio";

import ProveedoresSolicitudesAlta from "./Proveedores/SolicitudesAlta/SolicitudesAlta";
import ProveedoresSolicitudesBaja from "./Proveedores/SolicitudesBaja/SolicitudesBaja";

import Licitaciones from "./Licitaciones/Licitaciones";
import LicitacionesForm from "./Licitaciones/LicitacionesForm";
import Proveedores from "./Proveedores/Proveedores";
import ProveedoresForm from "./Proveedores/ProveedoresForm";
import Tramites from "./Configuracion/Tramites/Tramites";
import TramitesForm from "./Configuracion/Tramites/TramitesForm";
import Documentos from "./Configuracion/Documentos/Documentos";
import DocumentosForm from "./Configuracion/Documentos/DocumentosForm";
import Feriados from "./Configuracion/Feriados/Feriados";
import Usuarios from "./Seguridad/Usuarios/Usuarios";
import UsuariosForm from "./Seguridad/Usuarios/UsuariosForm";
import PerfilesForm from "./Seguridad/Perfiles/PerfilesForm";
import Perfiles from "./Seguridad/Perfiles/Perfiles";

import "./Layout.scss";
import Preguntas from "./Preguntas/Preguntas";

const Layout = () => {
  const history = useHistory();

  const minWidth = 992;
  const [collapsed, setCollapsed] = useState(window.innerWidth < minWidth);

  useEffect(() => {
    function windowResize() {
      setCollapsed(window.innerWidth < minWidth);
    }
    window.addEventListener("resize", windowResize);

    const user = getUser();
    if (user.nombre === "") {
      history.push("/login");
    }
  }, []);

  return (
    <div className="backoffice">
      <SideBar collapsed={collapsed} onCollapse={setCollapsed} />
      <main className="content">
        <Bar collapsed={collapsed} onCollapse={setCollapsed} />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route path="/contrataciones/:id" component={LicitacionesForm} />
          <Route path="/contrataciones" component={Licitaciones} />

          <Route
            path="/proveedores/solicitudes-alta/:id"
            component={ProveedoresForm}
          />
          <Route
            path="/proveedores/solicitudes-alta"
            component={ProveedoresSolicitudesAlta}
          />

          <Route
            path="/proveedores/solicitudes-baja"
            component={ProveedoresSolicitudesBaja}
          />

          <Route path="/proveedores/:id" component={ProveedoresForm} />
          <Route path="/proveedores" component={Proveedores} />

          <Route path="/configuracion/feriados" component={Feriados} />

          <Route path="/configuracion/tramites/:id" component={TramitesForm} />
          <Route path="/configuracion/tramites" component={Tramites} />

          <Route
            path="/configuracion/documentos/:id"
            component={DocumentosForm}
          />
          <Route path="/configuracion/documentos" component={Documentos} />

          <Route path="/seguridad/usuarios/:id" component={UsuariosForm} />
          <Route path="/seguridad/usuarios" component={Usuarios} />
          <Route path="/preguntas" component={Preguntas} />

          <Route path="/seguridad/perfiles/:id" component={PerfilesForm} />
          <Route path="/seguridad/perfiles" component={Perfiles} />

          <Route path="/" component={Inicio} />
        </AnimatedSwitch>
      </main>
      <div style={{ height: "30px" }}></div>
    </div>
  );
};

export default Layout;
