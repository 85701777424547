import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import {
  FiBriefcase,
  FiCalendar,
  FiDollarSign,
  FiFileText,
  FiFolder,
  FiFolderMinus,
  FiFolderPlus,
  FiShield,
  FiUser,
  FiMessageSquare,
} from "react-icons/fi";

import { actions, hasAction } from "./../../services/security";

import "./Inicio.scss";

const Inicio = () => {
  const sections = [
    {
      id: 1,
      title: "Inicio",
      modules: [
        {
          id: 1,
          title: "Contrataciones",
          url: "/contrataciones",
          icon: <FiDollarSign />,
          visible: hasAction(actions.LICITACIONES_VER),
        },
        {
          id: 11,
          title: "Preguntas",
          url: "/preguntas",
          icon: <FiMessageSquare />,
          visible: hasAction(actions.LICITACIONES_VER),
        },
      ],
      visible: hasAction(actions.LICITACIONES_VER),
    },
    {
      id: 2,
      title: "Proveedores",
      modules: [
        {
          id: 2,
          title: "Proveedores",
          url: "/proveedores",
          icon: <FiBriefcase />,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
        {
          id: 3,
          title: "Solicitudes de Alta",
          url: "/proveedores/solicitudes-alta",
          icon: <FiFolderPlus />,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
        {
          id: 4,
          title: "Solicitudes de Baja",
          url: "/proveedores/solicitudes-baja",
          icon: <FiFolderMinus />,
          visible: hasAction(actions.PROVEEDORES_VER),
        },
      ],
      visible: hasAction(actions.PROVEEDORES_VER),
    },
    {
      id: 3,
      title: "Configuración",
      modules: [
        {
          id: 6,
          title: "Trámites",
          url: "/configuracion/tramites",
          icon: <FiFolder />,
          visible: hasAction(actions.TRAMITES_VER),
        },
        {
          id: 7,
          title: "Documentos",
          url: "/configuracion/documentos",
          icon: <FiFileText />,
          visible: hasAction(actions.DOCUMENTOS_VER),
        },
        {
          id: 8,
          title: "Feriados",
          url: "/configuracion/feriados",
          icon: <FiCalendar />,
          visible: hasAction(actions.FERIADOS_VER),
        },
      ],
      visible:
        hasAction(actions.TRAMITES_VER) ||
        hasAction(actions.DOCUMENTOS_VER) ||
        hasAction(actions.FERIADOS_VER),
    },
    {
      id: 4,
      title: "Seguridad",
      modules: [
        {
          id: 9,
          title: "Perfiles",
          url: "/seguridad/perfiles",
          icon: <FiShield />,
          visible: hasAction(actions.PERFILES_VER),
        },
        {
          id: 10,
          title: "Usuarios",
          url: "/seguridad/usuarios",
          icon: <FiUser />,
          visible: hasAction(actions.USUARIOS_VER),
        },
      ],
      visible:
        hasAction(actions.USUARIOS_VER) || hasAction(actions.USUARIOS_VER),
    },
  ];

  return (
    <div className="inicio">
      {sections
        .filter((s) => s.visible)
        .map((s) => (
          <div key={s.id} className="section">
            <h2 className="title">{s.title}</h2>
            <Row gutter={[16, 24]}>
              {s.modules
                .filter((m) => m.visible)
                .map((m) => (
                  <Col key={m.id} xs={24} md={12} lg={8} xxl={6}>
                    <Link to={m.url}>
                      <div className="module card card-hover">
                        <div className="title">{m.title}</div>
                        <div className="icon">{m.icon}</div>
                      </div>
                    </Link>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
    </div>
  );
};

export default Inicio;
