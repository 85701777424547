/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Dropdown, Menu, Modal, Tag } from "antd";
import {
  FiCheck,
  FiCheckCircle,
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiMoreVertical,
  FiSearch,
  FiX,
} from "react-icons/fi";
import Viewer from "react-viewer";
import { SlideDown } from "react-slidedown";
import { FileIcon, defaultStyles } from "react-file-icon";

import http from "../../services/http";

import classes from "./Documentos.module.scss";

const Documentos = ({
  documentos,
  reload,
  update,
  readonly,
  registroFirmas,
  onChangeRegistroFirmas,
}) => {
  const [closed, setClosed] = useState(true);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const images = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".pdf"];

  const onClickPreview = (file) => {

    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);

    if (file.extension === ".pdf") {
      setPdfVisible(true)
    }
    else {

      setPreviewVisible(true);
    }

  };

  const onClickDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const changeStatus = async (file) => {
    const response = await http.put(`proveedores/archivos/${file.id}`, file);
    if (response && response.data.code === 0) {
      reload();
    }
  };

  const onClickReject = async (file) => {
    const nFile = { ...file, estado: 2 };
    changeStatus(nFile);
  };


  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  };

  const onClickValidate = async (file) => {
    const nFile = { ...file, estado: 3 };
    changeStatus(nFile);
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onClickPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onClickDownload(file)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {update && file.estado !== 2 && file.estado !== 4 && (
          <Menu.Item key="2" onClick={() => onClickReject(file)}>
            <FiX />
            Observar
          </Menu.Item>
        )}
        {update && file.estado !== 3 && file.estado !== 4 && (
          <Menu.Item key="3" onClick={() => onClickValidate(file)}>
            <FiCheck />
            Validar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const renderEstado = (file) => {
    let color = "";
    let text = "";

    switch (file.estado) {
      case 1:
        color = "gold";
        text = "Pendiente";
        break;

      case 2:
        color = "red";
        text = "Observado";
        break;

      case 3:
        color = "green";
        text = "Validado";
        break;

      case 4:
        color = "purple";
        text = "Obsoleto";
        break;

      default:
        break;
    }

    return <Tag color={color}>{text}</Tag>;
  };

  return (
    <div className={classes.documentos}>
      <div className={classes.header} onClick={() => setClosed(!closed)}>
        <div className={classes.data}>
          <div className={classes.title}>Documentación</div>
          <div className={classes.description}>
            Documentación asociada con la solicitud.
          </div>
        </div>
        <div className={classes.toggle}>
          {closed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>

      <SlideDown closed={closed}>
        {documentos.map((documento, i) => (
          <div className={classes.documento} key={i}>
            <div className={classes.info}>
              <div className={classes.icon}>
                <FileIcon extension=".pdf" {...defaultStyles.pdf} />
              </div>
              <div className={classes.title}>{documento.nombre}</div>
            </div>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th></th>
                  <th>Nombre</th>
                  <th className={classes.status}>Estado</th>
                  {/* <th className={classes.date}>Fecha</th>
                  <th className={classes.time}>Hora</th>
                  <th className={classes.size}>Tamaño</th> */}

                  <th></th>
                </tr>
              </thead>

              <tbody>
                {documento.archivos.length === 0 && (
                  <tr>
                    <td className={classes.empty} colSpan="6">
                      Sin resultados
                    </td>
                  </tr>
                )}

                {documento.archivos.map((documento, j) => (
                  <tr key={j}>
                    <td className={classes.actions}>
                      <Dropdown
                        overlay={() => menu(documento)}
                        trigger={["click"]}
                        placement="bottomLeft"
                        arrow
                      >
                        <div>
                          <FiMoreVertical />
                        </div>
                      </Dropdown>
                    </td>
                    <td>{documento.nombre}
                      &nbsp;
                      {documento.firmado && (
                        <div>
                          <FiCheckCircle title="Firmado" color="green" />
                          <small>Firmado</small>
                        </div>
                      )}
                    </td>
                    <td className={classes.status}>
                      {renderEstado(documento)}
                    </td>
                    {/* <td className={classes.date}>{documento.fecha}</td>
                    <td className={classes.time}>{documento.hora}</td>
                    <td className={classes.size}>{documento.tamanioTexto}</td> */}

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </SlideDown>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className="licitaciones-solicitud-form"
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>
      )}

    </div>
  );
};

export default Documentos;
