/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Dropdown, Menu, Modal } from "antd";
import {
  FiDownload,
  FiMoreVertical,
  FiSearch,
  FiTrash2,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Viewer from "react-viewer";
import Compressor from "compressorjs";
import { FileIcon, defaultStyles } from "react-file-icon";
import { hasAction, actions } from "./../../../services/security";
import { SlideDown } from "react-slidedown";

import { Context } from "./../../../components/Context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";

import "./Archivos.scss";

const Archivos = ({ id }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);
  const fileRef = useRef(null);

  const CREAR = hasAction(actions.TRAMITES_EDITAR);
  const EDITAR = hasAction(actions.TRAMITES_EDITAR);
  const GUARDAR = id > 0 ? CREAR : EDITAR;

  const [reload, setReload] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [closed, setClosed] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);


  const images = ["jpg", "jpeg", "bmp", "gif", "png", "pdf"];

  useEffect(() => {
    async function getData() {
      const response = await http.get(`tramites/${id}/archivos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setArchivos(data.list);
      }
    }

    getData();
  }, [id, state.reload, reload]);

  const onAdd = () => {
    fileRef.current.click();
  };

  
  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  };
  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes("image")) {
        await compress(files[i]);
      } else {
        await upload(files[i]);
      }
    }

    e.target.value = "";
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const compress = async (file) => {
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        await upload(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const upload = async (file) => {
    const base64 = await toBase64(file);

    const data = {
      nombre: file.name,
      tamanio: file.size.toString(),
      contenido: base64,
      tramiteId: id,
    };

    const response = await http.post(`tramites/${id}/archivos`, data);
    if (response && response.data.code === 0) {
      setReload(!reload);
    }
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === "pdf") {
      setPdfVisible(true)
    }
    else {

      setPreviewVisible(true);
    }
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este archivo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(
          `tramites/${id}/archivos/${item.id}`
        );
        if (response && response.data.code === 0) {
          showSuccess("El archivo fue eliminado exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {GUARDAR && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div className="archivos card">
      <div className="section-header" onClick={() => setClosed(!closed)}>
        <div className="section-information">
          <div className="section-title">Documentación</div>
          <div className="section-subtitle">
            Información adicional del trámite
          </div>
        </div>
        <div className="section-toggle">
          {closed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>
      <SlideDown className={"my-dropdown-slidedown"} closed={closed}>
        <table className="table-small">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th style={{ textAlign: "center" }}>Fecha</th>
              <th style={{ textAlign: "center" }}>Hora</th>
              {/* <th style={{ textAlign: "center" }}>Tamaño</th> */}
            </tr>
          </thead>
          <tbody>
            {archivos.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="6">
                  Sin resultados
                </td>
              </tr>
            )}

            {archivos.map((x) => (
              <tr key={x.id}>
                <td className="table-actions">
                  <Dropdown
                    key={x.id}
                    overlay={() => menu(x)}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                  >
                    <div>
                      <FiMoreVertical />
                    </div>
                  </Dropdown>
                </td>
                <td className="td-icon">
                  <div className="icon">
                    <FileIcon
                      extension={x.extension}
                      {...defaultStyles[x.extension]}
                    />
                  </div>
                  {x.nombre}
                </td>
                <td style={{ textAlign: "center" }}>{x.fecha}</td>
                <td style={{ textAlign: "center" }}>{x.hora}</td>
                {/* <td style={{ textAlign: "center" }}>{x.tamanioTexto}</td> */}
              </tr>
            ))}
          </tbody>
        </table>

        {GUARDAR && (
          <div className="form-actions">
            <input
              type="file"
              multiple={true}
              ref={fileRef}
              onChange={onUpload}
            />
            <Button type="primary" onClick={onAdd}>
              Subir
            </Button>
          </div>
        )}
      </SlideDown>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />
      
      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default Archivos;
