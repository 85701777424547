/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, DatePicker, Form, Input, Modal, Select, Row, Col } from "antd";
import { FiDollarSign } from "react-icons/fi";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  addBusinessDays,
  parseDateTime,
  parseToDateTime,
} from "../../services/forms";
import http from "../../services/http";
import { showSuccess, showWarning } from "../../services/notification";
import { actions, hasAction } from "../../services/security";
import { required } from "../../services/validation";

import Alert from "../../components/Alert";
import Header from "../../components/Header";
import Archivos from "./Archivos";
import Solicitudes from "./Solicitudes/Solicitudes";
import Documentos from "./Documentos";
import ActaApertura from "./ActaApertura";

const LicitacionesForm = ({ match, location }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [id, setId] = useState(
    match.params.id === "nuevo" ? 0 : parseInt(match.params.id)
  );
  const [title] = useState(id === 0 ? "Crear" : "Editar");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [readonly, setReadonly] = useState(
    id !== 0 && new URLSearchParams(location.search).get("edit") !== "true"
  );

  const [editor, setEditor] = useState();
  const [aperturaObligatoria, setAperturaObligatoria] = useState(false);
  const [verActaApertura, setVerActaApertura] = useState(false);
  const [verOferentes, setVerOferentes] = useState(false);

  const [actividades, setActividades] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tipos, setTipos] = useState([]);

  const VER = hasAction(actions.LICITACIONES_VER);
  const CREAR = hasAction(actions.LICITACIONES_CREAR);
  const EDITAR = hasAction(actions.LICITACIONES_EDITAR);
  const GUARDAR = (id === 0 && CREAR) || (id > 0 && EDITAR);

  const { Option } = Select;
  const [utmDisabled, setUtmDisabled] = useState(false);
  const handleTipoChange = (value) => {
    // Bloquea el campo UTM si el valor seleccionado es 2 o 3
    if (value === "2" || value === "3") {
      setUtmDisabled(true);
    } else {
      setUtmDisabled(false);
    }
  };

  const breadcrumb = [
    { id: 1, title: "Contrataciones", url: "/contrataciones" },
    {
      id: 2,
      title: title,
      url: `/contrataciones/${match.params.id}`,
    },
  ];

  useEffect(() => {
    const clearUser = async () => {
      if (readonly) {
        return;
      }

      await http.patch(`licitaciones/${id}/editor`);
    };

    const handleTabClose = async (event) => {
      event.preventDefault();
      await clearUser();
      return true;
    };

    window.addEventListener("beforeunload", handleTabClose);

    return async () => {
      await clearUser();
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("licitaciones/data");

      if (response && response.data.code === 0) {
        let data = response.data.data;
        setAreas(data.areas);
        setActividades(data.actividades);
        setTipos(data.tipos);
      }

      response = await http.get(`licitaciones/${id}?edit=${!readonly}`);
      if (response && response.data.code === 0) {
        let data = response.data.data;

        setReadonly(data.editor !== undefined || readonly);
        setEditor(data.editor);
        setAperturaObligatoria(data.aperturaObligatoria);
        setVerActaApertura(data.verActaApertura);
        setVerOferentes(data.verOferentes);

        data.desde = parseToDateTime(data.desde);
        data.hasta = parseToDateTime(data.hasta);
        data.hastaProrroga = parseToDateTime(data.hastaProrroga);

        data.aperturaDesde = parseToDateTime(data.aperturaDesde);
        data.aperturaHasta = parseToDateTime(data.aperturaHasta);

        data.postAperturaDesde = parseToDateTime(data.postAperturaDesde);
        data.postAperturaHasta = parseToDateTime(data.postAperturaHasta);

        data.preAdjudicacionDesde = parseToDateTime(data.preAdjudicacionDesde);
        data.preAdjudicacionHasta = parseToDateTime(data.preAdjudicacionHasta);

        data.sectores = data.sectores && data.sectores.map((x) => x.toString());
        data.destinos = data.destinos && data.destinos.map((x) => x.toString());
        data.entidades =
          data.entidades && data.entidades.map((x) => x.toString());
        data.tramites = data.tramites && data.tramites.map((x) => x.toString());

        form.setFieldsValue(data);
      }
    }

    getForm();
  }, []);

  const onChangeHasta = (value) => {
    const aperturaDesde = value.add(1, "minutes");
    const postAperturaHasta = value ? addBusinessDays(value, 2) : undefined;
    const preAdjudicacionDesde = value ? addBusinessDays(value, 2) : undefined;

    form.setFieldsValue({
      aperturaDesde: aperturaDesde,
      postAperturaHasta: postAperturaHasta,
      preAdjudicacionDesde: preAdjudicacionDesde,
    });
  };
  const onChangeAperturaDesde = (value) => {
    debugger;
    const vigenciaHasta = value.add(-1, "minutes");
    form.setFieldsValue({
      hasta: vigenciaHasta,
    });
  };

  const onChangeAperturaHasta = (value) => {
    form.setFieldsValue({
      postAperturaDesde: value,
    });
  };

  const onSave = async (values) => {
    if (
      values.aperturaDesde &&
      values.aperturaHasta &&
      values.aperturaDesde.isAfter(values.aperturaHasta)
    ) {
      showWarning(
        "La Apertura Desde no puede ser posterior a la Apertura Hasta"
      );
      return;
    }

    values.desde = parseDateTime(values.desde);
    values.hasta = parseDateTime(values.hasta);
    values.hastaProrroga = parseDateTime(values.hastaProrroga);

    values.aperturaDesde = parseDateTime(values.aperturaDesde);
    values.aperturaHasta = parseDateTime(values.aperturaHasta);

    values.postAperturaDesde = parseDateTime(values.postAperturaDesde);
    values.postAperturaHasta = parseDateTime(values.postAperturaHasta);

    values.preAdjudicacionDesde = parseDateTime(values.preAdjudicacionDesde);
    values.preAdjudicacionHasta = parseDateTime(values.preAdjudicacionHasta);

    setLoading(true);

    const response =
      id === 0
        ? await http.post("licitaciones", values)
        : await http.put(`licitaciones/${id}`, values);

    setLoading(false);

    if (response && response.data.code === 0) {
      showSuccess("La contratación fue guardada exitosamente.");

      if (id > 0) {
        history.goBack();
        return;
      }

      const data = response.data.data;

      Modal.confirm({
        title: "Continuar editando",
        icon: <ExclamationCircleOutlined />,
        content: "¿Desea seguir editando este tramite?",
        okText: "Confirmar",
        cancelText: "Cancelar",
        onOk: () => {
          setId(data.id);
        },
        onCancel: () => history.push("/configuracion/tramites"),
      });
    }
  };

  if (!VER) {
    return <Redirect to="" />;
  }

  return (
    <div className="licitaciones-form">
      <div className="card card-page">
        <Header
          title={`${title} Contratación`}
          breadcrumb={breadcrumb}
          icon={<FiDollarSign />}
        />
        <Form
          form={form}
          layout="vertical"
          className="form-page"
          onFinish={onSave}
        >
          {editor && (
            <Alert type="warning">
              Este proveedor esta siendo editado por <strong>{editor}</strong>.
            </Alert>
          )}

          <Row gutter={[24]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Número de Contratación"
                name="numero"
                rules={[required]}
              >
                <Input maxLength={200} readOnly={readonly} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Número de Expediente"
                name="expediente"
                rules={[required]}
              >
                <Input maxLength={200} readOnly={readonly} />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item label="Objeto" name="nombre" rules={[required]}>
                <Input maxLength={200} readOnly={readonly} />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Tipo"
                name="tipo"
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={readonly}
                  onChange={handleTipoChange}
                >
                  {tipos.map((tipo, index) => (
                    <Option key={index} value={tipo.value}>
                      {tipo.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item label="Url Compras Mendoza" name="url">
                <Input maxLength={200} readOnly={readonly} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item label="Presupuesto" name="presupuesto">
                <Input type="number" readOnly={readonly} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label="Pliego" name="pliego">
                <Input type="number" readOnly={readonly} />
              </Form.Item>
            </Col>
            {!utmDisabled && (
              <Col xs={24} lg={8}>
                <Form.Item label="UTM" name="utm">
                  <Input type="number" readOnly={readonly} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} lg={8}>
              <Form.Item label="Vigencia Desde" name="desde" rules={[required]}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label="Vigencia Hasta" name="hasta" rules={[required]}>
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  onChange={onChangeHasta}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label="Vigencia Hasta (Prorroga)" name="hastaProrroga">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  onChange={onChangeHasta}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Apertura Desde" name="aperturaDesde">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  onChange={onChangeAperturaDesde}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Apertura Hasta"
                name="aperturaHasta"
                rules={aperturaObligatoria && [required]}
              >
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  onChange={onChangeAperturaHasta}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item label="Post Apertura Desde" name="postAperturaDesde">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="Post Apertura Hasta" name="postAperturaHasta">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  placeholder=""
                  locale={locale}
                  showTime={true}
                  disabled={readonly}
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Actividades"
                name="actividades"
                rules={[required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={readonly}
                >
                  {actividades.map((g) => (
                    <Select.Option key={g.value} value={g.value}>
                      {g.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item label="Area Solicitante" name="area">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={readonly}
                >
                  {areas.map((g) => (
                    <Select.Option key={g.value} value={g.value}>
                      {g.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {id > 0 && (
        <Documentos
          licitacion={id}
          readonly={readonly}
          reload={reload}
          toggleReload={() => setReload(!reload)}
        />
      )}
      {id > 0 && (
        <Archivos
          licitacion={id}
          readonly={readonly}
          reload={reload}
          toggleReload={() => setReload(!reload)}
        />
      )}
      {id > 0 && verActaApertura && (
        <ActaApertura
          licitacion={id}
          readonly={readonly}
          reload={reload}
          toggleReload={() => setReload(!reload)}
        />
      )}
      {id > 0 && verOferentes && (
        <Solicitudes
          licitacion={id}
          readonly={readonly}
          reload={reload}
          toggleReload={() => setReload(!reload)}
        />
      )}

      <div className="form-actions">
        <Button type="secondary" onClick={history.goBack}>
          Volver
        </Button>
        {GUARDAR && !readonly && (
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LicitacionesForm;
