import React, { useState } from "react";
import { SlideDown } from "react-slidedown";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import SolicitudesDocumentos from "./SolicitudesDocumentos";
import SolicitudesDocumentosAdicionales from "./SolicitudesDocumentosAdicionales";
import SolicitudesMensajes from "./SolicitudesMensajes";

import "react-slidedown/lib/slidedown.css";
import "./SolicitudesItem.scss";

const SolicitudesItem = (props) => {
  const { licitacion, solicitud } = props;
  const [closed, setClosed] = useState(true);

  return (
    <React.Fragment>
      <div className="solicitudes-item">
        <div className="header" onClick={() => setClosed(!closed)}>
          <div className="data">
            <div className="name">{solicitud.proveedor}</div>
            <div className="cuil">{solicitud.proveedorCuil} </div>
          </div>
          <div className="toggle">
            {closed ? <FiChevronDown /> : <FiChevronUp />}
          </div>
        </div>
        <SlideDown className={"my-dropdown-slidedown"} closed={closed}>
          {!closed && solicitud.documentosVer && (
            <SolicitudesDocumentos
              licitacion={licitacion}
              solicitudId={solicitud.id}
              agregar={solicitud.documentosAgregar}
              actualizar={solicitud.documentosActualizar}
            />
          )}
          {!closed && solicitud.mensajesVer && (
            <SolicitudesMensajes
              licitacion={licitacion}
              solicitudId={solicitud.id}
              agregar={solicitud.mensajesAgregar}
            />
          )}
          {!closed && solicitud.documentosAdicionalesVer && (
            <SolicitudesDocumentosAdicionales
              licitacion={licitacion}
              solicitudId={solicitud.id}
              agregar={solicitud.documentosAdicionalesAgregar}
            />
          )}
        </SlideDown>
      </div>
    </React.Fragment>
  );
};

export default SolicitudesItem;
